import * as React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/search/search.scss';
import PageTitleSection from '../components/structure/page-header';

let park = process.env.GATSBY_PARK,
  pageTitle = `Search`,
  // As this page only shows for non-existent pages, no breadcrumb
  // data will exist, so we add some in manually
  staticCrumbTrail = [
    {
      node: {
        enabled: true,
        title: 'Home',
        menu_name: `${park}-top-nav`,
        link: { uri: '/', uri_alias: '/' },
        description: null,
        drupal_id: '',
        bundle: 'menu_link_content',
        drupal_parent_menu_item: null,
        weight: -50,
        external: false,
        children: [],
      },
    },
  ];

const searchResultPage = () => {
  // default to CORPorate site_code
  let site_code, site_domain, path_prefix;
  // @ts-ignore
  switch (park.toUpperCase()) {
    case 'AMP':
      site_code = 'e23fd12dc8db74bd3';
      site_domain = 'australianmarineparks.gov.au';
      path_prefix = 'marine';
      break;
    case 'ANBG':
      site_code = '407b127e24ec64eca';
      site_domain = 'visit.anbg.gov.au';
      path_prefix = 'botanic-gardens';
      break;
    case 'BNP':
      site_code = '73d14a5939e5e451e';
      site_domain = 'booderee.gov.au';
      path_prefix = 'booderee';
      break;
    case 'CINP':
      site_code = 'e539d828cdd81453c';
      site_domain = 'christmasislandnationalpark.gov.au';
      path_prefix = 'christmas';
      break;
    case 'KNP':
      site_code = '55e7d2e443fe8453a';
      site_domain = 'kakadu.gov.au';
      path_prefix = 'kakadu';
      break;
    case 'NINP':
      site_code = 'c6648b67fd16a40b6';
      site_domain = 'norfolkislandnationalpark.gov.au';
      path_prefix = 'norfolk';
      break;
    case 'PKNP':
      site_code = '56aacb76bd6f84f71';
      site_domain = 'pulukeelingnationalpark.gov.au';
      path_prefix = 'pulu-keeling';
      break;
    case 'UKTNP':
      site_code = '302e1936588744c80';
      site_domain = 'uluru.gov.au';
      path_prefix = 'uluru';
      break;
    default:
      site_code = '525e7e8716ba147cf';
      site_domain = 'parksaustralia.gov.au';
      path_prefix = '';
  }
  let site_engine = 'https://cse.google.com/cse.js?cx=' + site_code;
  return (
    <>
      <PageTitleSection
        title={pageTitle}
        socialMediaBlock
        breadcrumbs={staticCrumbTrail}
        summaryText
      />
      <title>Search</title>
      <main
        id="content"
        className={'pb-medium searchContainer park-' + park}
        data-domain={site_domain}
        data-pathprefix={path_prefix}
      >
        <div className="grid-wrapper">
          <div className="grid-row clearfix">
            <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
              <Helmet>
                <script async src={site_engine}></script>
              </Helmet>
              <div className="gcse-search"></div>
              {/* <script src="/js/search.js"></script> */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default searchResultPage;
